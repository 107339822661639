<template>
  <div>
    <label for="message" class="input-label">Message</label>
    <input name="message" class="input" v-model="message.current" />
    <label for="streamURL" class="mt-2 input-label">URL</label>
    <input name="streamURL" class="input" v-model="url.current" />
    <label for="streamURL" class="mt-2 input-label">Status</label>
    <div class="list-item" @click="live.current = !live.current">
      <Checkbox class="cursor-pointer" :selected="live.current" />
      <div>Live</div>
    </div>
    <div class="flex items-center mt-2">
      <Button primary @click="isOpen = true">Change</Button>
      <Spinner v-if="loading" class="w-6 ml-2 text-red-500" />
    </div>
  </div>
  <Modal :open="isOpen" @close="onCancel">
    <template #header>
      <h1 class="text-lg">
        Are you sure you want to change the stream settings?
      </h1>
    </template>
    <template #content>
      <div v-if="live.current">
        <div class="text-xs">Show on stream:</div>
        <a
          v-if="url.current"
          :href="`https://www.youtube.com/watch?v=${url.current}`"
          target="_blank"
        >
          <div
            class="flex items-center w-full px-4 py-2 space-x-2 bg-gray-200 rounded-md cursor-pointer hover:bg-opacity-80"
          >
            <VideoCameraIcon class="text-red-500 icon-sm" />
            <div>YouTube Video</div>
          </div>
        </a>
        <div
          v-else
          class="flex items-center w-full px-4 py-2 space-x-2 bg-gray-200 rounded-md hover:bg-opacity-80"
        >
          <BellIcon class="text-red-500 icon-sm" />
          <div>
            {{ message.current }}
          </div>
        </div>
      </div>
      <div v-else>
        Show poster page.
      </div>
    </template>
    <template #footer>
      <Button @click="onSubmit" primary>Confirm</Button>
      <Button @click="onCancel" secondary>Cancel</Button>
    </template>
  </Modal>
</template>

<script>
import { ref, reactive } from "vue";
import { db } from "../firebase";
import Spinner from "./shared/Spinner";
import Button from "./shared/Button";
import Modal from "./shared/Modal";
import Checkbox from "./shared/Checkbox";
import { VideoCameraIcon, BellIcon } from "./icons";

export default {
  components: { Spinner, Button, Modal, Checkbox, VideoCameraIcon, BellIcon },
  setup() {
    const url = reactive({
      previous: "",
      current: "",
    });
    const message = reactive({
      previous: "",
      current: "",
    });
    const live = reactive({
      previous: false,
      current: false,
    });
    const loading = ref(false);
    const isOpen = ref(false);

    const onSubmit = async () => {
      loading.value = true;
      await db
        .collection("settings")
        .doc("stream")
        .update({
          url: url.current,
          message: message.current,
          live: live.current,
        });
      url.previous = url.current;
      message.previous = message.current;
      live.previous = live.current;
      loading.value = false;
      isOpen.value = false;
    };

    const fetchStreamUrl = async () => {
      const questionSettingsRef = await db
        .collection("settings")
        .doc("stream")
        .get();
      const data = questionSettingsRef.data();
      url.current = url.previous = data.url;
      message.previous = message.current = data.message;
      live.current = live.previous = data.live;
    };

    const onCancel = () => {
      url.current = url.previous;
      message.current = message.previous;
      live.current = live.previous;
      isOpen.value = false;
    };

    fetchStreamUrl();

    return {
      url,
      loading,
      isOpen,
      onCancel,
      message,
      onSubmit,
      live,
    };
  },
};
</script>
