<template>
  <button
    type="button"
    class="p-2 text-xs cursor-pointer group"
    @click="isOpen = true"
  >
    <TrashIcon class="icon-sm icon-action" />
  </button>
  <Modal :open="isOpen" @close="isOpen = false">
    <template #header>
      Are you sure you want to delete this poll?
    </template>
    <template #content>
      <div
        class="flex items-center w-full px-4 py-2 mt-2 space-x-2 bg-gray-300 rounded hover:bg-opacity-80"
      >
        <TrashIcon class="text-red-500 icon-sm" />
        <div>
          {{ question.title }}
        </div>
      </div>
    </template>
    <template #footer>
      <Button primary @click="deleteQuestion">Confirm</Button>
      <Button secondary @click="isOpen = false">Cancel</Button>
    </template>
  </Modal>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Modal from "./shared/Modal";
import Button from "./shared/Button";
import { TrashIcon } from "./icons";
import { DELETE_POLL_QUESTION } from "../store/actions";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: { Modal, Button, TrashIcon },
  setup(props) {
    const store = useStore();
    const question = computed(() =>
      store.state.pollQuestions.find(q => q.id === props.id)
    );
    const isOpen = ref(false);

    const deleteQuestion = async () => {
      store.dispatch(DELETE_POLL_QUESTION, props.id);
      isOpen.value = false;
    };

    return {
      isOpen,
      deleteQuestion,
      question,
    };
  },
};
</script>
