<template>
  <div>
    <label for="title" class="input-label">Title</label>
    <input v-model="title" name="title" class="input" />
    <label class="mt-2 input-label">Answers</label>
    <ul class="p-1 space-y-2 bg-gray-100 rounded">
      <li
        v-for="answer in answers"
        :key="answer.index"
        class="flex items-center"
      >
        <input class="input" v-model="answer.text" />
        <div
          class="p-2 text-xs cursor-pointer group"
          @click="deleteAnswer(answer.index)"
        >
          <MinusCircleIcon class="icon-sm icon-action" />
        </div>
      </li>
      <button
        v-if="answers.length < maxNumOfAnswers"
        @click="addAnswer"
        class="flex items-center justify-center w-full py-1 border-2 border-dashed rounded cursor-pointer hover:border-solid group"
        type="button"
      >
        <PlusCircleIcon class="text-gray-300 icon-sm" />
      </button>
    </ul>
    <div class="flex items-center mt-2">
      <Button primary @click="modalOpen = answers.length >= minNumOfAnswers"
        >Add</Button
      >
      <Spinner v-if="loading.newPoll" class="w-6 ml-2 text-red-500" />
    </div>
    <Modal :open="modalOpen" @close="modalOpen = false">
      <template #header>
        <p class="text-xs text-gray-400">Poll Preview</p>
        <h1 class="text-lg">{{ title }}</h1>
      </template>
      <template #content>
        <ul class="mt-2 space-y-1">
          <li
            v-for="answer in answers.filter(a => a.text)"
            :key="answer.index"
            class="px-4 py-2 bg-gray-200 rounded hover:bg-opacity-80"
          >
            {{ answer.text }}
          </li>
        </ul>
      </template>
      <template #footer>
        <Button
          :disabled="
            !title || answers.filter(a => a.text).length < minNumOfAnswers
          "
          primary
          @click="handleSubmit"
          >Confirm</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import Button from "./shared/Button";
import Spinner from "./shared/Spinner";
import Modal from "./shared/Modal";
import { PlusCircleIcon, MinusCircleIcon } from "./icons";
import { ADD_POLL_QUESTION } from "../store/actions";

const minNumOfAnswers = 2;
const maxNumOfAnswers = 6;

export default {
  components: { Button, Spinner, Modal, PlusCircleIcon, MinusCircleIcon },
  setup() {
    const store = useStore();
    const title = ref("");
    const answers = ref([]);
    const modalOpen = ref(false);

    const addAnswer = () => {
      if (answers.value.length < maxNumOfAnswers) {
        answers.value.push({
          index: answers.value.length,
          text: "",
        });
      }
    };

    const addInitialAnswers = () => {
      for (let i = 0; i < minNumOfAnswers; ++i) {
        addAnswer();
      }
    };

    const deleteAnswer = index => {
      if (answers.value.length <= minNumOfAnswers) {
        answers.value[index].text = "";
      } else {
        answers.value = answers.value
          .filter(answer => answer.index !== index)
          .map((answer, index) => ({ ...answer, index }));
      }
    };

    const handleSubmit = async () => {
      if (title.value && answers.value.length >= minNumOfAnswers) {
        store.dispatch(ADD_POLL_QUESTION, {
          title: title.value,
          answers: answers.value,
        });

        answers.value = [];
        addInitialAnswers();
        title.value = "";
        modalOpen.value = false;
      }
    };

    onMounted(() => {
      addInitialAnswers();
    });

    return {
      title,
      answers,
      addAnswer,
      deleteAnswer,
      handleSubmit,
      maxNumOfAnswers,
      minNumOfAnswers,
      loading: computed(() => store.state.loading),
      modalOpen,
    };
  },
};
</script>
