<template>
  <LoadingScreen v-if="loading" />
  <div v-else>
    <div
      v-if="!currentUser"
      class="flex items-center justify-center min-h-screen bg-gray-100"
    >
      <Card class="w-96">
        <h1 class="text-lg border-b-2">Login</h1>
        <LoginForm class="mt-4" />
      </Card>
    </div>
    <PageLayout v-else title="Admin">
      <template v-slot:header>
        <div class="flex items-center">
          <p class="hidden mr-4 text-xs text-gray-500 md:inline">
            {{ currentUser.email }}
          </p>
          <Button primary @click="handleSignOut">Sign Out</Button>
        </div>
      </template>
      <div class="flex flex-col items-center my-4 space-y-4">
        <Card v-for="card in cards" :key="card.title" class="w-full">
          <h1 class="text-lg border-b-2">{{ card.title }}</h1>
          <div class="mt-4">
            <component :is="card.component" />
          </div>
        </Card>
      </div>
    </PageLayout>
  </div>
</template>

<script>
import { ref } from "vue";
import { auth } from "../firebase";

import Button from "../components/shared/Button.vue";
import Spinner from "../components/shared/Spinner";
import LoginForm from "../components/LoginForm.vue";
import Messages from "../components/Messages.vue";
import PollAdd from "../components/PollAdd";
import PollList from "../components/PollList";
import StreamSettings from "../components/StreamSettings";
import PageLayout from "../layouts/PageLayout";
import Card from "../components/shared/Card";
import LoadingScreen from "../components/LoadingScreen";

export default {
  components: {
    Button,
    LoginForm,
    Messages,
    PollAdd,
    PollList,
    StreamSettings,
    PageLayout,
    Spinner,
    Card,
    LoadingScreen,
  },
  setup() {
    const currentUser = ref(undefined);
    const loading = ref(true);

    const handleSignOut = () => {
      auth.signOut();
    };

    auth.onAuthStateChanged(user => {
      currentUser.value = user;
      loading.value = false;
    });

    return {
      loading,
      currentUser,
      handleSignOut,
      cards: [
        { title: "Questions", component: Messages },
        { title: "Polls", component: PollList },
        { title: "Add poll", component: PollAdd },
        { title: "Stream settings", component: StreamSettings },
      ],
    };
  },
};
</script>
