<template>
  <div class="relative">
    <div
      v-if="loading.pollQuestions"
      class="inset-0 flex items-center justify-center bg-gray-50"
      :class="questions.length ? 'absolute h-full' : 'h-16'"
    >
      <Spinner class="text-red-500 icon-lg" />
    </div>
    <ul class="w-full space-y-2">
      <li
        v-for="question in questions"
        :key="question.id"
        class="flex items-center"
      >
        <div class="w-full list-item" @click="selectQuestion(question.id)">
          <div>
            <Checkbox :selected="selectedQuestion === question.id" />
          </div>
          <div>
            {{ question.title }}
          </div>
          <div
            v-if="shownQuestion === question.id"
            class="text-xs rounded-full"
          >
            <ChevronIcon class="w-4 text-red-500 transform -rotate-90" />
          </div>
        </div>
        <PollResults :id="question.id"></PollResults>
        <PollDelete :id="question.id"></PollDelete>
      </li>
    </ul>
    <div class="flex items-center mt-2 space-x-2">
      <Button primary @click="setSelectedQuestion">Show</Button>
      <Spinner v-if="loading.selection" class="w-6 text-red-500" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { db } from "../firebase";

import Checkbox from "./shared/Checkbox.vue";
import Button from "./shared/Button";
import Spinner from "./shared/Spinner";
import PollResults from "./PollResults";
import PollDelete from "./PollDelete";
import { ChevronIcon } from "./icons";
import { FETCH_POLL_QUESTIONS, SET_LOADING } from "../store/actions";

export default {
  components: {
    Checkbox,
    Button,
    Spinner,
    PollResults,
    PollDelete,
    ChevronIcon,
  },
  setup() {
    const store = useStore();
    const selectedQuestion = ref("");
    const shownQuestion = ref("");

    const fetchQuestions = async () => {
      store.dispatch(FETCH_POLL_QUESTIONS);
    };

    const fetchSelectedQuestion = async () => {
      const settings = await db
        .collection("settings")
        .doc("stream")
        .get();
      const questionId = settings.data().question;
      selectedQuestion.value = questionId;
      shownQuestion.value = questionId;
    };

    const setSelectedQuestion = async () => {
      store.commit(SET_LOADING, { selection: true });

      await db
        .collection("settings")
        .doc("stream")
        .update({ question: selectedQuestion.value });
      shownQuestion.value = selectedQuestion.value;

      store.commit(SET_LOADING, { selection: false });
    };

    const selectQuestion = questioId => {
      if (selectedQuestion.value === questioId) {
        selectedQuestion.value = "";
      } else {
        selectedQuestion.value = questioId;
      }
    };

    fetchQuestions();
    fetchSelectedQuestion();

    return {
      setSelectedQuestion,
      selectedQuestion,
      shownQuestion,
      selectQuestion,
      fetchQuestions,
      loading: computed(() => store.state.loading),
      questions: computed(() => store.state.pollQuestions),
    };
  },
};
</script>
