<template>
  <button
    type="button"
    class="p-2 text-xs cursor-pointer group"
    @click="isOpen = true"
  >
    <ChartPieIcon class="icon-sm icon-action" />
  </button>
  <Modal :open="isOpen" @close="isOpen = false">
    <template #header>
      <p class="text-xs text-gray-400">Poll Results</p>
      <h1 class="text-lg">{{ question.title }}</h1>
    </template>
    <template #content>
      <div v-if="loading" class="flex items-center justify-center h-16">
        <Spinner class="text-red-500 icon-lg" />
      </div>
      <div v-else>
        <ul class="mt-2 space-y-1">
          <li
            v-for="answer in question.answers"
            :key="answer.id"
            class="flex items-center w-full px-4 py-2 bg-gray-200 rounded hover:bg-opacity-80"
          >
            <div class="flex-grow">
              {{ answer.text }}
            </div>
            <div class="whitespace-nowrap">
              {{ answer.percentage }}% ({{ answer.count }})
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <Button primary @click="isOpen = false">Close</Button>
    </template>
  </Modal>
</template>

<script>
import { watch, reactive, ref } from "vue";
import { db } from "../firebase";
import Spinner from "./shared/Spinner";
import Modal from "./shared/Modal";
import Button from "./shared/Button";
import { ChartPieIcon } from "./icons";

export default {
  components: { Spinner, Modal, Button, ChartPieIcon },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const question = reactive({});
    const loading = ref(false);
    const isOpen = ref(false);

    watch(isOpen, value => {
      if (value) fetchPollResults();
    });

    const fetchPollResults = async () => {
      loading.value = true;
      const questionRef = db.collection("questions").doc(props.id);
      const fetchedQuestion = await questionRef.get();
      const fetchedAnswers = (
        await questionRef.collection("answers").get()
      ).docs.map(d => ({ id: d.id, ...d.data() }));

      question.id = fetchedQuestion.id;
      question.title = fetchedQuestion.data().title;

      const answersTotal = fetchedAnswers.reduce(
        (acc, curr) => acc + curr.count,
        0
      );
      question.answers = fetchedAnswers
        .sort((a, b) => a.order - b.order)
        .map(answer => {
          const percentage = answersTotal
            ? Math.round((answer.count / answersTotal) * 1000) / 10
            : 0;
          return { ...answer, percentage };
        });
      loading.value = false;
    };

    return {
      question,
      loading,
      isOpen,
    };
  },
};
</script>
