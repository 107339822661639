<template>
  <form @submit.prevent="handleLogin" class="flex flex-col justify-center">
    <div>
      <label for="email" class="input-label">Email</label>
      <input type="email" name="email" v-model="email" class="input" />
    </div>
    <div class="mt-2">
      <label for="password" class="input-label">Password</label>
      <input type="password" name="password" v-model="password" class="input" />
    </div>
    <div class="flex items-center mt-2 space-x-2">
      <Button primary submit>Login</Button>
      <router-link to="/"><Button secondary>Go Back</Button></router-link>
      <Spinner v-if="loading" class="text-red-500 icon-sm" />
      <transition enter-active-class="animate-fade-in">
        <div v-if="errorMessage" class="flex items-center space-x-1">
          <ExclamationCircleIcon class="text-red-500 icon-sm" />
          <span>Login failed</span>
        </div>
      </transition>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { auth } from "../firebase";
import Button from "./shared/Button";
import Spinner from "./shared/Spinner";
import { ExclamationCircleIcon } from "./icons";

export default {
  components: { Button, Spinner, ExclamationCircleIcon },
  setup() {
    const email = ref("");
    const password = ref("");
    const errorMessage = ref("");
    const loading = ref(false);

    const handleLogin = () => {
      errorMessage.value = "";

      if (email.value && password.value) {
        loading.value = true;
        auth
          .signInWithEmailAndPassword(email.value, password.value)
          .catch(error => {
            errorMessage.value = error.message;
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    return {
      email,
      password,
      handleLogin,
      errorMessage,
      loading,
    };
  },
};
</script>
